<template>
  <v-container id="role" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showError">
        {{ showErrorData }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col>
              <v-text-field
                label="Name"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row v-for="userRole in modulePermission" :key="userRole._id">
            <v-col>
              {{ userRole.module_name }}
              <!-- <v-checkbox
              v-model="moduleSelected"
              :label=userRole.module_name
              :value=userRole._id
            ></v-checkbox> -->
              <v-row>
                <v-col
                  v-for="permission in userRole.permissions"
                  :key="permission._id"
                >
                  <v-checkbox
                    v-model="permissionSelected"
                    :label="permission.name"
                    :value="permission._id"
                    :disabled="onlyView"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                <!-- {{ editRole == true ? "Edit" : "Add" }} -->
                {{
                  onlyView == true
                    ? "Back"
                    : editRole == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      modulePermission: [],
      permissionSelected: [],
      editRole: null,
      showError: false,
      name: "",
      inputRule: [(v) => !!v || "Field is required"],
      onlyView: false,
      loading: false,
    };
  },
  methods: {
    getRoleData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "role/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            let permissions = [];
            response.data.role.user_permissions.forEach((permission) => {
              permissions.push(
                permission.module_id + "." + permission.permission_id
              );
            });
            this.permissionSelected = permissions;
            this.name = response.data.role.name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getModulePermission() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "module/permission/getall")
        .then((response) => {
          if (response.status == 200) {
            this.modulePermission = response.data.modulePermission;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          name: this.name,
          permission: this.permissionSelected,
        };
        if (this.onlyView) {
          this.$router.push({ name: "Roles" });
        } else if (this.editRole == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "role/update", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Roles", params: { edit: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "role/create", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Roles", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        }
      }
    },
  },
  mounted() {
    this.getModulePermission();
    if (this.id != undefined) {
      this.getRoleData(this.id);
      this.editRole = true;
    } else {
      this.editRole = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>

<style>
</style>